import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { useFormikContext } from 'formik';

import { ReceivementContext } from '..';

import { VALID_SERVICE_ORDER_GRV } from 'services/graphql/query/service-orders';
import * as XLSX from 'xlsx'; // Biblioteca XLSX

const examples = [
  { value: 1, label: 'Utilize o layout do GRV-Diário' },
  { value: 2, label: 'Não retire o cabeçalho da planilha' },
  { value: 3, label: 'Não formate ou coloque fórmulas na planilha' },
  { value: 4, label: 'Salve o arquivo como .xlsx' }, // Alterado para XLSX
];

export function ImportGRVProducts() {
  const { setResults, getProviderSelect } = useContext(ReceivementContext);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const { values, isValid, dirty } = useFormikContext();

  const [validProducts] = useMutation(VALID_SERVICE_ORDER_GRV, {
    onCompleted: (data) => {
      toast.success('Produtos importados com sucesso!');
      setImporting(false);
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';
      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleReset = () => {
    setResults();
    inputFileRef.current.value = '';
  };

  async function handleImportStore(e) {
    setImporting(true);
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo XLSX');
        setImporting(false);
        return;
      }

      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });

          // Seleciona a primeira planilha
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Converte os dados para JSON
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Pega o cabeçalho e as linhas de dados
          const [header, ...dataRows] = rows;

          // Processa as linhas para criar os produtos
          const products = dataRows
            .map((row) => {
              if (
                !row[10] || // VOUCHER
                !row[2] || // CPF
                !row[3] || // CLIENTE
                !row[4] || // TELEFONE
                !row[5] || // EMAIL
                !row[7] || // CEP
                !row[9] || //   CITY
                !row[12] || // DATA
                !row[13] || // LOJA
                !row[15] || // CONDICAO CLIENTE
                !row[17] || // CONDICAO LOJA
                !row[18] || // VALOR
                !row[19] || // CONDICAO FINAL
                !row[21] || // IMEI
                !row[25] || // SKU
                !row[28] || // RESULTADO
                !row[29] || // MATCH
                !row[30] // QUESTIONS
              ) {
                return null;
              }

              return {
                sinister: String(row[10]),
                cgc: row[2],
                client_name: row[3],
                phone_number: row[4],
                email: row[5],
                zip_code: row[7],
                city: row[9],
                aprove_date: row[12],
                store: row[13],
                client_condition: row[15],
                store_condition: row[17],
                value: row[18],
                final_condition: row[19],
                imei: String(row[21]),
                sku: row[25],
                result: row[28],
                match: row[29],
                questions: row[30],
              };
            })
            .filter((item) => item !== null);
          console.log('products ==>', products);
          await validProducts({
            variables: { data: products, provider_id: getProviderSelect },
          });
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
        } finally {
          setImporting(false);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false);
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xlsx'
        onChange={(e) => handleImportStore(e)}
        accept='.xlsx'
        hidden
      />
      <Button
        fullWidth
        color='primary'
        onClick={() => handleOpenDialog()}
        disabled={!isValid || !dirty}
      >
        Importar Produtos
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
