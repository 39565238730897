import React from 'react';
import { AiOutlinePullRequest } from 'react-icons/ai';
import { BiAddToQueue, BiTransfer } from 'react-icons/bi';
import { BiImport } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import { CgTrack } from 'react-icons/cg';
import { FaMicrochip, FaBoxOpen, FaRegMoneyBillAlt, FaFileAlt } from 'react-icons/fa';
import { GiMailbox } from 'react-icons/gi';
import { HiCollection } from 'react-icons/hi';
import { ImDrawer, ImUserCheck } from 'react-icons/im';
import { ImCancelCircle } from 'react-icons/im';
import { RiSearchEyeLine } from 'react-icons/ri';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BuildIcon from '@material-ui/icons/Build';
import CallIcon from '@material-ui/icons/Call';
import CheckIcon from '@material-ui/icons/Check';
import DevicesIcon from '@material-ui/icons/Devices';
import DoneAllIcon from '@material-ui/icons/Done';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import NotesIcon from '@material-ui/icons/Notes';
import PanToolIcon from '@material-ui/icons/PanTool';
import SearchIcon from '@material-ui/icons/Search';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import UpdateIcon from '@material-ui/icons/Update';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import BarChartIcon from '@mui/icons-material/BarChart';
import BlockIcon from '@mui/icons-material/Block';
import BugReportIcon from '@mui/icons-material/BugReport';
import BungalowIcon from '@mui/icons-material/Bungalow';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import HandymanIcon from '@mui/icons-material/Handyman';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InventoryIcon from '@mui/icons-material/Inventory';
import MediationIcon from '@mui/icons-material/Mediation';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PrintIcon from '@mui/icons-material/Print';
import SavingsIcon from '@mui/icons-material/Savings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';

export const menu = [
  { id: 'home', path: '/home', label: 'Ínicio', icon: <HomeIcon />, roles: 1 },
  {
    id: 'administration',
    label: 'Administração',
    icon: <SupervisorAccountIcon size='18' />,
    roles: 2,
    items: [
      {
        path: '/monthly-closing',
        label: 'Fechamento mensal',
        icon: <SavingsIcon size='18' />,
        type: 'sub',
        roles: 209,
      },
    ],
  },
  {
    id: 'general-registration',
    label: 'Cadastro Gerais',
    icon: <BsPencilSquare size='18' />,
    roles: 2,
    items: [
      {
        path: '/register-acessory',
        label: 'Acessórios de Service',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 101,
      },
      {
        path: '/register-clerk',
        label: 'Atendente',
        icon: <AddReactionIcon size='18' />,
        type: 'sub',
        roles: 102,
      },
      // {
      //   path: '/register-seller',
      //   label: 'Cadastro de Contrato Correio',
      //   icon: <BiAddToQueue size='18' />,
      //   type: 'sub',
      //   roles: 114,
      // },

      {
        path: '/create-quote-smartphone-brand',
        label: 'Cadastro de marca de Smartphone para cotação',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 168,
      },

      {
        path: '/create-quote-smartphone-model',
        label: 'Cadastro de modelo de Smartphone para cotação',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 168,
      },

      {
        path: '/create-object-blocking-reason',
        label: 'Cadastro de Motivo de Bloqueio',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 212,
      },

      {
        path: '/create-quote-smartphone-use-time',
        label: 'Cadastro de tempo de uso de Smartphone para cotação',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 168,
      },

      {
        path: '/create-quote-smartphone-insurance-type',
        label: 'Cadastro de tipo de seguro para Smartphone na cotação',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 168,
      },

      {
        path: '/register-shipping',
        label: 'Cadastro de Transportadora',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 168,
      },

      {
        path: '/register-sub-insurance',
        label: 'Cadastro de Seguradora',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 177,
      },

      {
        path: '/register-contract-sigep',
        label: 'Cadastrar Contrato SIGEP',
        icon: <CheckIcon size='18' />,
        roles: 168,
      },
      {
        path: '/register-client',
        label: 'Cliente',
        icon: <AddReactionIcon size='18' />,
        type: 'sub',
        roles: 159,
      },
      {
        path: '/create-device-defect',
        label: 'Defeito de Dispositivo',
        icon: <AddReactionIcon size='18' />,
        type: 'sub',
        roles: 158,
      },
      {
        path: '/create-billingexpiration',
        label: 'Expiração da Billing',
        icon: <UpdateIcon size='18' />,
        type: 'sub',
        roles: 103,
      },
      {
        path: '/create-components-sales-price',
        label: 'Gerar Preço de Componentes',
        icon: <UpdateIcon size='18' />,
        type: 'sub',
        roles: 104,
      },

      // {
      //   path: '/create-log',
      //   label: 'Log',
      //   icon: <BiAddToQueue size='18' />,
      //   type: 'sub',
      //   roles: ['ti'],
      // },
      // {
      //   path: '/create-account',
      //   label: 'Novo usuário',
      //   icon: <PersonAddIcon size='18' />,
      //   type: 'sub',
      //   roles: 105,
      // },
      {
        path: '/register-sigep_range',
        label: 'Range SIGEP',
        icon: <LabelImportantIcon size='18' />,
        type: 'sub',
        roles: 106,
      },
      {
        path: '/register-accessory-price',
        label: 'Preço de acessórios',
        icon: <AttachMoneyIcon size='18' />,
        type: 'sub',
        roles: 107,
      },
      {
        path: '/register-service-products',
        label: 'Preço de componentes',
        icon: <AttachMoneyIcon size='18' />,
        type: 'sub',
        roles: 108,
      },

      {
        path: '/register-service-colors',
        label: 'Cadastrar COR',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 109,
      },
      {
        path: '/register-model',
        label: 'Produto',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 109,
      },
      {
        path: '/single-registration-of-tax-coupon',
        label: 'Registro único de Cupom Fiscal',
        icon: <GppMaybeIcon size='18' />,
        type: 'sub',
        roles: 110,
      },
      {
        path: '/register-budget-status',
        label: 'Status de orçamento',
        icon: <UpdateIcon size='18' />,
        type: 'sub',
        roles: 111,
      },
      {
        path: '/create-sub-device-defect',
        label: 'Sub Defeito de Dispositivo',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 160,
      },
      {
        path: '/create-billingexpirationtype',
        label: 'Tipo de Billing',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 112,
      },
      {
        path: '/register-process',
        label: 'Tipo de processo',
        icon: <BiAddToQueue size='18' />,
        type: 'sub',
        roles: 113,
      },
      {
        path: '/register-service-budget',
        label: 'Tipo de serviço para orçamento',
        icon: <AttachMoneyIcon size='18' />,
        type: 'sub',
        roles: 114,
      },
      {
        path: '/register-seller',
        label: 'Vendedor',
        icon: <UpdateIcon size='18' />,
        type: 'sub',
        roles: 161,
      },
    ],
  },
  {
    id: 'cashier',
    label: 'Caixa',
    icon: <AttachMoneyIcon />,
    roles: 3,
    items: [
      {
        path: '/consult-note-no-fiscal',
        label: 'Consultar Cupom Fiscal',
        icon: <PriceCheckIcon />,
        roles: 115,
      },
      {
        path: '/consult-note',
        label: 'Consultar notas',
        icon: <SearchIcon />,
        roles: 116,
      },
      {
        path: '/issuance-of-analysis-note',
        label: 'Emitir nota de análise',
        icon: <TimelineIcon />,
        roles: 117,
      },

      {
        path: '/labour',
        label: 'Taxa de Análise',
        icon: <HandymanIcon />,
        roles: 119,
      },

      {
        path: '/report-cashier',
        label: 'Relatório diário de caixa',
        icon: <HandymanIcon />,
        roles: 119,
      },

      {
        path: '/register-service-price-no-order',
        label: 'Cadastrar tipo de serviço sem GSPN',
        icon: <HandymanIcon />,
        roles: 120,
      },
      {
        path: '/acessory-sale',
        label: 'Vendas',
        icon: <LoyaltyIcon />,
        roles: 121,
      },
      {
        path: '/view-voucher',
        label: 'Visualizar comprovante',
        icon: <SearchIcon />,
        roles: 122,
      },
    ],
  },
  {
    id: 'quote',
    label: 'Cotação',
    icon: <SummarizeIcon />,
    roles: 16,
    items: [
      {
        path: '/quote-consult-transactions',
        label: 'Consultar Cotações de Celulares',
        icon: <DoneAllIcon />,
        roles: 180,
      },
      {
        path: '/quote-smartphone',
        label: 'Cotação de Celular',
        icon: <DoneAllIcon />,
        roles: 180,
      },
    ],
  },

  {
    id: 'collaborators',
    label: 'Colaboradores',
    icon: <PersonAddIcon />,
    roles: 17,
    items: [
      {
        path: '/report-aging',
        label: 'Relatório AGING',
        icon: <DoneAllIcon />,
        roles: 186,
      },
      {
        path: '/graphic-budget-sac',
        label: 'Gráfico SAC',
        icon: <BarChartIcon />,
        roles: 205,
      },
      {
        path: '/search-price-samsung',
        label: 'Consultar preço',
        icon: <FaRegMoneyBillAlt />,
        roles: 206,
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Controle de Acesso',
    icon: <PersonAddIcon />,
    roles: 14,
    items: [
      {
        path: '/create-role',
        label: 'Novo Grupo de Usuário',
        icon: <PersonAddIcon />,
        roles: 151,
      },
      {
        path: '/list-profiles',
        label: 'Perfis',
        icon: <PersonAddIcon />,
        roles: 162,
      },
      {
        path: '/permissions',
        label: 'Permissionamento',
        icon: <PersonAddIcon />,
        roles: 152,
      },
    ],
  },
  // NÃO EXCLUIR
  {
    id: 'conference',
    label: 'Conferência',
    icon: <AttachMoneyIcon />,
    roles: 4,
    items: [
      { path: '/opening-pll', label: 'Pré abertura SEURADORA', icon: <SearchIcon />, roles: 152 },
    ],
  },
  {
    id: 'stock',
    label: 'Estoque',
    icon: <FaBoxOpen size='18' />,
    roles: 4,
    items: [
      {
        path: '/update-balance',
        label: 'Atualizar saldo',
        icon: <InventoryIcon size='18' />,
        type: 'sub',
        roles: 167,
      },
      {
        path: '/analysis-stock-request',
        label: 'Analisar Requisições de Estoque',
        icon: <SearchIcon size='18' />,
        roles: 169,
      },
      {
        path: '/finalize-cmp',
        label: 'Baixa de PARTS ON HOLDING',
        icon: <SearchIcon size='18' />,
        roles: 169,
      },
      {
        path: '/register-component-alternate',
        label: 'Cadastrar Alternate',
        icon: <FaMicrochip size='18' />,
        roles: 207,
      },
      {
        path: '/register-component',
        label: 'Cadastrar componente',
        icon: <FaMicrochip size='18' />,
        roles: 123,
      },
      {
        path: '/create-stock-location',
        label: 'Cadastro de local de estoque',
        icon: <InventoryIcon size='18' />,
        type: 'sub',
        roles: 167,
      },
      // {
      //   path: '/register-location',
      //   label: 'Cadastrar local',
      //   icon: <HiOutlineLocationMarker size='18' />,
      // },
      {
        path: '/register-invoice',
        label: 'Cadastrar nota fiscal',
        icon: <NoteAddIcon size='18' />,
        roles: 124,
      },
      {
        path: '/purchase-requisition',
        label: 'Confirmar encomenda',
        icon: <AiOutlinePullRequest size='18' />,
        roles: 125,
      },
      {
        path: '/consult-billing',
        label: 'Consultar Billing',
        icon: <SearchIcon size='18' />,
        roles: 126,
      },
      {
        path: '/consult-stock',
        label: 'Consultar estoque',
        icon: <SearchIcon size='18' />,
        roles: 127,
      },
      {
        path: '/consult-billingpn',
        label: 'Consultar PN por Billing',
        icon: <SearchIcon size='18' />,
        roles: 128,
      },
      {
        path: '/register-component-debit',
        label: 'Debitar Componente',
        icon: <AssignmentLateIcon size='18' />,
        roles: 128,
      },
      {
        path: '/stock-devolution',
        label: 'Devolução de estoque',
        icon: <AssignmentReturnIcon size='18' />,
        roles: 129,
      },

      {
        path: '/stock-importation',
        label: 'Importação manual de componentes',
        icon: <ImportExportIcon size='18' />,
        roles: 155,
      },
      {
        path: '/import-stock-components',
        label: 'Importar Estoque',
        icon: <ImportExportIcon />,
        roles: 156,
      },
      {
        path: '/import-stock',
        label: 'Importar Estoque por Planilha',
        icon: <ImportExportIcon />,
        roles: 177,
      },
      {
        path: '/import-stock',
        label: 'Importar Atualização de Preço do estoque',
        icon: <ImportExportIcon />,
        roles: 177,
      },
      {
        path: '/movement-stock',
        label: 'Movimentação de Estoque',
        icon: <BiTransfer size='18' />,
        roles: 170,
      },
      {
        path: '/pn-tracker',
        label: 'PN-Tracker',
        icon: <CgTrack size='18' />,
        roles: 130,
      },
      {
        path: '/balance-transfer',
        label: 'Transferência',
        icon: <BiTransfer size='18' />,
        roles: 131,
      },
      {
        path: '/reprint-component-tags',
        label: 'Reimpressão de etiqueta de componentes',
        icon: <BiTransfer size='18' />,
        roles: 131,
      },
    ],
  },
  {
    id: 'expedition',
    label: 'Expedição',
    icon: <LocalShippingIcon />,
    roles: 5,
    items: [
      {
        path: '/emission-invoice',
        label: 'Emitir Etiqueta',
        icon: <BiTransfer size='18' />,
        roles: 171,
      },
      {
        path: '/reflect-invoice',
        label: 'Espelhar Nota',
        icon: <BiTransfer size='18' />,
        roles: 172,
      },
      {
        path: '/assurant-minute',
        label: 'Geração de Minuta Assurant',
        icon: <LocalShippingIcon />,
        roles: 187,
      },
      {
        path: '/',
        label: 'Minuta',
        icon: <LocalShippingIcon />,
        roles: 132,
      },
      // {
      //   path: '/minute-batch',
      //   label: 'Minuta em Lote',
      //   icon: <LocalShippingIcon />,
      //   roles: 132,
      // },
      {
        path: '/reprint-post-card',
        label: 'Reimpressão de Cartão de Postagem',
        icon: <PrintIcon size='18' />,
        roles: 132,
      },
      {
        path: '/reprint-danfe',
        label: 'Reimpressão de DANFE',
        icon: <PrintIcon size='18' />,
        roles: 132,
      },
      {
        path: '/reprint_minute-batch',
        label: 'Reimpressão de Lote de Minuta',
        icon: <PrintIcon size='18' />,
        roles: 132,
      },
      // {
      //   path: '/pre-expedition',
      //   label: 'Pré-Expedição',
      //   icon: <ImportExportIcon />,
      //   roles: 132,
      // },
      // {
      //   path: '/revenue',
      //   label: 'Minuta',
      //   icon: <VerifiedUserIcon />,
      // },
    ],
  },
  {
    id: 'warranty',
    label: 'Garantia',
    icon: <VerifiedUserIcon />,
    roles: 6,
    items: [
      {
        path: '/warranty-validation',
        label: 'Validar garantia',
        icon: <DoneAllIcon />,
        roles: 133,
      },
    ],
  },
  {
    id: 'implementation',
    label: 'Implementação',
    icon: <ImDrawer />,
    roles: 7,
    items: [
      // {
      //   path: '/import-bases',
      //   label: 'Importar Base',
      //   icon: <DownloadingIcon />,
      //   roles: 156,
      // },
    ],
  },
  {
    id: 'general-releases',
    label: 'Liberações de liderança',
    icon: <SupervisedUserCircleIcon size='18' />,
    roles: 8,
    items: [
      {
        path: '/lib-release',
        label: 'Liberar garantia',
        icon: <CheckIcon size='18' />,
        roles: 135,
      },
      {
        path: '/release-qa',
        label: 'Liberar QA',
        icon: <CheckIcon size='18' />,
        roles: 165,
      },
      {
        path: '/group-of-changes',
        label: 'Trocar grupo',
        icon: <GroupIcon size='18' />,
        roles: 136,
      },
    ],
  },
  {
    id: 'service-order',
    label: 'Ordem de Serviço',
    icon: <FaFileAlt />,
    roles: 9,
    items: [
      {
        path: '/opening',
        label: 'Abertura',
        icon: <BuildIcon />,
        roles: 137,
      },
      {
        path: '/import-services',
        label: 'Importar services',
        icon: <BiImport />,
        roles: 134,
      },
      {
        path: '/alter-gaia-service',
        label: 'Alterar OS no GAIA',
        icon: <LabelImportantIcon />,
        roles: 157,
      },
      {
        path: '/cancel-gaia-service',
        label: 'Cancelar OS no GAIA',
        icon: <ImCancelCircle size='18' />,
        roles: 138,
      },
      {
        path: '/consult',
        label: 'Consultar',
        icon: <RiSearchEyeLine size='18' />,
        roles: 139,
      },
      {
        path: '/advanced-consult',
        label: 'Consulta Avançada',
        icon: <RiSearchEyeLine size='18' />,
        roles: 139,
      },

      // {
      //   path: '/register-batchitems',
      //   label: 'Registrar itens LOTE SEGURADORA',
      //   icon: <ImCancelCircle />,
      //   roles: 179,
      // },
      // {
      //   path: '/register-batch',
      //   label: 'Registrar lote SEGURADORA',
      //   icon: <ImCancelCircle />,
      //   roles: 179,
      // },
      {
        path: '/reprint-service-tag',
        label: 'Reimpressão de Etiqueta de Abertura',
        icon: <PrintIcon size='18' />,
        roles: 140,
      },
    ],
  },
  {
    id: 'pcp',
    label: 'PCP',
    icon: <ImUserCheck size='18' />,
    roles: 10,
    items: [
      {
        path: '/awaiting-withdrawal',
        label: 'Aguardando retirada',
        icon: <PanToolIcon size='18' />,
        roles: 141,
      },

      {
        path: '/general',
        label: 'Disponível para orçamento',
        icon: <NotesIcon size='18' />,
        roles: 142,
      },

      // {
      //   path: '/awaiting-finishinhome',
      //   label: 'Encerrar services IN HOME',
      //   icon: <HomeWorkIcon size='18' />,
      //   roles: 143,
      // },
      {
        path: '/inhomeservice',
        label: 'Services de instalação inicial IN HOME',
        icon: <BungalowIcon size='18' />,
        roles: 142,
      },
      // {
      //   path: '/awaiting-rh',
      //   label: 'Listagem de services RH',
      //   icon: <NotesIcon size='18' />,
      //   roles: 141,
      // },
    ],
  },
  {
    id: 'production',
    label: 'Produção',
    icon: <DevicesIcon />,
    roles: 11,
    items: [
      {
        path: '/attach-report-os',
        label: 'Anexar Laudo',
        icon: <NotesIcon />,
        roles: 166,
      },
      { path: '/check-kit', label: 'Check Kit', icon: <MarkunreadMailboxIcon />, roles: 145 },
      {
        path: '/convert-many-xml',
        label: 'Converter XMLs',
        icon: <NotesIcon />,
        roles: 166,
      },
      {
        path: '/postal-cards',
        label: 'Cartões expedição',
        icon: <MarkunreadMailboxIcon />,
        roles: 145,
      },
      {
        path: '/awaiting-parts',
        label: 'Enviar para aguardando componentes',
        icon: <MarkunreadMailboxIcon />,
        roles: 145,
      },
      {
        path: '/convert-many-xml',
        label: 'Converter XMLs',
        icon: <NotesIcon />,
        roles: 166,
      },
      {
        path: '/stock-requisition',
        label: 'Requisição de estoque',
        icon: <NotesIcon />,
        roles: 173,
      },
      {
        path: '/stock-requisition-pending',
        label: 'Requisição de estoque pendências',
        icon: <NotesIcon />,
        roles: 173,
      },
      {
        path: '/pre-budget-tec',
        label: 'Pré Orçamento Técnico',
        icon: <CheckIcon />,
        roles: 153,
      },
      { path: '/quality-assurance', label: 'QA', icon: <MarkunreadMailboxIcon />, roles: 164 },
      { path: '/sorting-assurance', label: 'Triagem', icon: <MarkunreadMailboxIcon />, roles: 164 },
      {
        path: '/validate-analysis-grade',
        label: 'Validar nota de análise',
        icon: <CheckIcon />,
        roles: 146,
      },
      {
        path: '/attach-report-view-os',
        label: 'Visualizar Laudo',
        icon: <NotesIcon />,
        roles: 166,
      },
    ],
  },
  {
    id: 'receivement',
    label: 'Recebimento',
    icon: <GiMailbox />,
    roles: 12,
    items: [
      {
        path: '/block-object',
        label: 'Bloqueio de Objetos',
        icon: <BlockIcon />,
        roles: 211,
      },
      { path: '/collect-object', label: 'Coleta de Objetos', icon: <HiCollection />, roles: 147 },
      {
        path: '/conference-invoice-repair',
        label: 'Conferência de nota de reparo',
        icon: <HiCollection />,
        roles: 174,
      },
      {
        path: '/opening-todo',
        label: 'Conferência services para abrir',
        icon: <HiCollection />,
        roles: 179,
      },
      {
        path: '/eticket-generate',
        label: 'Gerar E-Ticket',
        icon: <ConfirmationNumberIcon />,
        roles: 147,
      },
      {
        path: '/import-many-invoice',
        label: 'Importar Notas de reparo em lote',
        icon: <NotesIcon />,
        roles: 178,
      },
      {
        path: '/reprint-invoice-repair-tag',
        label: 'Reimpressão de etiqueta de conferência',
        icon: <NotesIcon />,
        roles: 178,
      },
      {
        path: '/reprint-invoice-repair-packing-list',
        label: 'Reimpressão de Romaneio MAGAZINE LOUVEIRA',
        icon: <NotesIcon />,
        roles: 185,
      },

      {
        path: '/reprint-invoice-repair-packing-list-diversos',
        label: 'Reimpressão de Romaneio MAGAZINE DIVERSOS',
        icon: <NotesIcon />,
        roles: 185,
      },

      {
        path: '/reprint-invoice-repair-packing-list-devol',
        label: 'Reimpressão de Romaneio DEVOL',
        icon: <NotesIcon />,
        roles: 185,
      },
      {
        path: '/reprint-invoice-repair-packing-list-all',
        label: 'Reimpressão de Romaneio Geral',
        icon: <NotesIcon />,
        roles: 185,
      },

      {
        path: '/awaiting-collect',
        label: 'Aguardando coleta VAREJO',
        icon: <HiCollection />,
        roles: 174,
      },

      {
        path: '/alter-conference',
        label: 'Alterar conferência',
        icon: <NotesIcon />,
        roles: 185,
      },
    ],
  },
  {
    id: 'reports-general',
    label: 'Relatórios',
    icon: <SummarizeIcon />,
    roles: 16,
    items: [
      {
        path: '/report-apple',
        label: 'Relatório apple',
        icon: <DoneAllIcon />,
        roles: 210,
      },
      {
        path: '/report-aging4h',
        label: 'Relatório aging 4 horas',
        icon: <DoneAllIcon />,
        roles: 188,
      },
      {
        path: '/report-aging',
        label: 'Relatório aging',
        icon: <DoneAllIcon />,
        roles: 189,
      },
      {
        path: '/report-agingDate',
        label: 'Relatório aging Data',
        icon: <DoneAllIcon />,
        roles: 190,
      },
      {
        path: '/report-cost',
        label: 'Relatório custos',
        icon: <DoneAllIcon />,
        roles: 208,
      },
      {
        path: '/report-component-debit',
        label: 'Relatório de débito de componentes',
        icon: <DoneAllIcon />,
        roles: 191,
      },
      {
        path: '/report-conference-all',
        label: 'Relatório total de conferência',
        icon: <DoneAllIcon />,
        roles: 192,
      },
      {
        path: '/report-etickets',
        label: 'Relatórios de etickets',
        icon: <DoneAllIcon />,
        roles: 193,
      },
      {
        path: '/report-financial',
        label: 'Relatório financeiro',
        icon: <DoneAllIcon />,
        roles: 181,
      },
      {
        path: '/report-final-insurance',
        label: 'Relatório final seguradoras',
        icon: <DoneAllIcon />,
        roles: 194,
      },
      {
        path: '/report-insurance-all',
        label: 'Relatório sinistros',
        icon: <DoneAllIcon />,
        roles: 195,
      },
      {
        path: '/report-minute',
        label: 'Relatório de minuta Assurant',
        icon: <DoneAllIcon />,
        roles: 196,
      },
      {
        path: '/report-nf-receive',
        label: 'Relatório recebimento NF',
        icon: <DoneAllIcon />,
        roles: 197,
      },
      {
        path: '/report-nonCompliance-quality',
        label: 'Relatório de não conforme QA',
        icon: <DoneAllIcon />,
        roles: 198,
      },
      {
        path: '/report-object',
        label: 'Relatório objetos e NF',
        icon: <DoneAllIcon />,
        roles: 183,
      },
      {
        path: '/report-budget',
        label: 'Relatório orçamentos',
        icon: <DoneAllIcon />,
        roles: 182,
      },
      {
        path: '/report-object-blocked',
        label: 'Relatório de Objetos Bloqueados',
        icon: <DoneAllIcon />,
        roles: 213,
      },
      {
        path: '/report-objects-received',
        label: 'Relatórios gráficos diários',
        icon: <DoneAllIcon />,
        roles: 199,
      },
      {
        path: '/report-partsHolding',
        label: 'Relatório de aguardando CMP',
        icon: <DoneAllIcon />,
        roles: 200,
      },
      {
        path: '/report-partsRequisitions',
        label: 'Relatório requisição de peças',
        icon: <DoneAllIcon />,
        roles: 201,
      },
      {
        path: '/report-prebudget',
        label: 'Relatório pré orçamento',
        icon: <DoneAllIcon />,
        roles: 180,
      },
      {
        path: '/report-productionDepartment',
        label: 'Relatório de produção por setor',
        icon: <DoneAllIcon />,
        roles: 202,
      },
      {
        path: '/report-retailInboundOutbound',
        label: 'Relatório entrada e saída varejo',
        icon: <DoneAllIcon />,
        roles: 203,
      },
      {
        path: '/report-sac',
        label: 'Relatório etapas SAC',
        icon: <DoneAllIcon />,
        roles: 204,
      },
    ],
  },
  {
    id: 'sac',
    label: 'SAC',
    icon: <CallIcon />,
    roles: 13,
    items: [
      {
        path: '/cancel-linksafe2pay',
        label: 'Cancelar Link de pagamento',
        icon: <PhonelinkEraseIcon />,
        roles: 148,
      },

      {
        path: '/template',
        label: 'Laudo de orçamento/Reparo',
        icon: <CheckIcon />,
        roles: 174,
      },

      {
        path: '/pending-swap',
        label: 'Pendência SWAP',
        icon: <PhonelinkEraseIcon />,
        roles: 148,
      },

      {
        path: '/swap-finalized',
        label: 'SWAP Finalizados',
        icon: <PhonelinkEraseIcon />,
        roles: 148,
      },
      {
        path: '/cancel-budget',
        label: 'Cancelar orçamento',
        icon: <ImCancelCircle />,
        roles: 149,
      },
      {
        path: '/create-budgetretail',
        label: 'Criar cobrança Varejo',
        icon: <MonetizationOnIcon />,
        roles: 176,
      },
      {
        path: '/pre-budget-tec-check',
        label: 'Consultar Pré Orçamento Técnico',
        icon: <CheckIcon />,
        roles: 154,
      },

      {
        path: '/calc-fare',
        label: 'Calculadora de FRETE ',
        icon: <PhonelinkEraseIcon />,
        roles: 148,
      },
      {
        path: '/issue-invoice-budget',
        label: 'Emitir nota de orçamento',
        icon: <CheckIcon />,
        roles: 163,
      },

      {
        path: '/issue-invoice-newkit',
        label: 'Emitir nota de KIT NOVO',
        icon: <CheckIcon />,
        roles: 163,
      },
      {
        path: '/budget-kit',
        label: 'Orçamento de kit',
        icon: <FaRegMoneyBillAlt />,
        roles: 150,
      },
    ],
  },
  {
    id: 'warehouse',
    label: 'Warehouse',
    icon: <WarehouseIcon />,
    roles: 19,
    items: [
      {
        path: '/waiting-allocation',
        label: 'Aguardando Alocação',
        icon: <WarehouseIcon />,
        roles: 223,
      },
      {
        path: '/waiting-oracle',
        label: 'Aguardando Oracle',
        icon: <MediationIcon />,
        roles: 224,
      },
      {
        path: '/b2b',
        label: 'B2B',
        icon: <BusinessCenterIcon />,
        roles: 227,
      },
      {
        path: '/b2c',
        label: 'B2C',
        icon: <BusinessCenterIcon />,
        roles: 230,
      },
      // {
      //   path: '/service',
      //   label: 'Atendimento',
      //   icon: <ReceiptLongIcon />,
      //   roles: 214,
      // },
      {
        path: '/dock',
        label: 'Doca',
        icon: <BungalowIcon />,
        roles: 228,
      },
      {
        path: '/stock',
        label: 'Estoque',
        icon: <InventoryIcon size='18' />,
        roles: 214,
      },
      {
        path: '/stock-resume',
        label: 'Estoque Resumido',
        icon: <InventoryIcon size='18' />,
        roles: 229,
      },
      {
        path: '/expedition',
        label: 'Expedição',
        icon: <LocalShippingIcon />,
        roles: 217,
      },
      {
        path: '/orders-management',
        label: 'Gerenciamento de Pedidos',
        icon: <DisplaySettingsIcon />,
        roles: 237,
      },
      {
        path: '/report',
        label: 'Laudo',
        icon: <BugReportIcon />,
        roles: 225,
      },
      {
        path: '/stock-locations',
        label: 'Locais do Estoque',
        icon: <AddLocationAltIcon />,
        roles: 222,
      },
      // {
      //   path: '/request',
      //   label: 'Pedidos',
      //   icon: <FaBoxOpen size='18' />,
      //   roles: 215,
      // },
      {
        path: '/picking',
        label: 'Picking',
        icon: <ContentPasteSearchIcon />,
        roles: 231,
      },
      {
        path: '/sigep',
        label: 'Range dos Correios',
        icon: <AllInboxIcon />,
        roles: 215,
      },
      {
        path: '/receivement',
        label: 'Recebimento',
        icon: <WidgetsIcon />,
        roles: 219,
      },
      {
        path: '/reprint-invoice',
        label: 'Reimpressão de Danfe',
        icon: <PrintIcon />,
        roles: 232,
      },
      {
        path: '/reprint-service-tags',
        label: 'Reimpressão de etiqueta',
        icon: <PrintIcon />,
        roles: 226,
      },
      {
        path: '/cosmetic-screening',
        label: 'Triagem Cosmética',
        icon: <AddToHomeScreenIcon />,
        roles: 221,
      },
      {
        path: '/functional-screening',
        label: 'Triagem Funcional',
        icon: <AddToHomeScreenIcon />,
        roles: 220,
      },
    ],
  },
  {
    id: 'warehouse-report',
    label: 'Warehouse - Relatórios',
    icon: <SummarizeIcon />,
    roles: 20,
    items: [
      {
        path: '/closing-assurant',
        label: 'Fechamento Assurant',
        icon: <DoneAllIcon />,
        roles: 233,
      },
      {
        path: '/in-out',
        label: 'Relatório de IN OUT',
        icon: <DoneAllIcon />,
        roles: 235,
      },
      {
        path: '/b2c-order-report',
        label: 'Relatório de Pedidos B2C',
        icon: <DoneAllIcon />,
        roles: 236,
      },
      {
        path: '/receiving',
        label: 'Relatório de Recebimento',
        icon: <DoneAllIcon />,
        roles: 234,
      },
    ],
  },
];
