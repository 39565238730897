import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
export function WarehouseExpeditionObjectCollectDialog({
  isOpen,
  handleClose,
  selectedItems,
  generateMinutePdf,
}) {
  if (!isOpen) {
    return <></>;
  }

  const [collectedItem, setCollectedItem] = useState(selectedItems);

  const firstUncollectedItem = collectedItem.find((item) => !item.collected);
  console.log('collectedItem ==>', collectedItem);
  const handleSubmit = async (values) => {
    try {
      setCollectedItem((prevItems) =>
        prevItems.map((item) =>
          item.invoice === values.invoice
            ? { ...item, collected: true, object: values.object }
            : item,
        ),
      );
    } catch (error) {
      console.error('Erro ao coletar objeto:', error);
    }
  };

  const handleGenerateMinute = async () => {
    try {
      await generateMinutePdf({ variables: { data: collectedItem } });
    } catch (error) {
      toast.error('Erro ao gerar a minuta', error);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth='sm' onClose={handleClose}>
      <Dialog.Title onClose={handleClose}>Coletar objeto</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={4} alignContent='right'>
            <Typography textAlign='center'>
              Objetos coletados:{' '}
              <strong style={{ color: 'green' }}>
                {collectedItem.reduce(
                  (accumulator, currentValue) => accumulator + (currentValue.collected ? 1 : 0),
                  0,
                )}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign='center'>
              Objetos restantes:{' '}
              <strong style={{ color: 'red' }}>
                {collectedItem.reduce(
                  (accumulator, currentValue) => accumulator + (currentValue.collected ? 0 : 1),
                  0,
                )}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Content>
        <Formik
          initialValues={{ object: '', invoice: firstUncollectedItem?.invoice ?? '' }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={3} xl={3}>
                  <Field
                    label='Nota fiscal'
                    name='invoice'
                    size='small'
                    variant='outlined'
                    component={Text}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={9} xl={9}>
                  <Field
                    label='Objeto'
                    name='object'
                    size='small'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Button fullWidth type='submit' disabled={!firstUncollectedItem}>
                    Coletar objeto
                  </Button>
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Button
                    fullWidth
                    disabled={firstUncollectedItem}
                    color='secondary'
                    onClick={() => handleGenerateMinute()}
                  >
                    Gerar minuta
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
