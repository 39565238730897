import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { useFormikContext } from 'formik';
import { VALID_SERVICE_ORDER } from 'services/graphql/query/service-orders';

import { ReceivementContext } from '..';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do TRADEIN_GERAL',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportProducts() {
  const { setResults, getProviderSelect } = useContext(ReceivementContext);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const { values, isValid, dirty } = useFormikContext();

  const [validProducts] = useMutation(VALID_SERVICE_ORDER, {
    onCompleted: (data) => {
      toast.success('Produtos importados com sucesso!');
      setImporting(false);
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleReset = () => {
    setResults();
    inputFileRef.current.value = '';
  };

  async function handleImportStore(e) {
    setImporting(true);
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false);
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              if (
                values[0] == null || // VOUCHER
                values[1] == null || // SKU
                values[3] == null || // APARELHO
                values[4] == null || // CONDIÇÃO
                values[5] == null || // IMEI
                values[7] == null || // DATA APROVAÇÃO
                values[10] == null || // VALOR TOTAL A PAGAR
                values[11] == null || // VENDEDOR
                values[12] == null || // CLIENTE
                values[13] == null || // CPF
                values[14] == null || // EMAIL
                values[15] == null || // TELEFONE
                values[20] == null || // CEP
                values[22] == null || // UF LOJA
                values[23] == null || // LOJA
                values[27] == null || // LOTE
                values[31] == null || // REDE
                values[32] == null // CODIGO DA LOJA
              ) {
                return null;
              }

              return {
                sinister: values[0],
                sku: values[1],
                product: values[3],
                condition: values[4],
                imei: values[5],
                aprove_date: values[7],
                value: values[10],
                seller: values[11],
                client_name: values[12],
                cgc: values[13],
                email: values[14],
                phone_number: values[15],
                zip_code: values[20],
                storeUF: values[22],
                store: values[23],
                batch: values[27],
                storeProvider: values[31],
                storeCode: values[32],
              };
            })
            .filter((item) => item !== null);

          const { data } = await validProducts({
            variables: { data: products, provider_id: getProviderSelect },
          });

          setResults(data?.validProductWithServiceOrder);
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false);
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false);
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />
      <Button
        fullWidth
        color='primary'
        onClick={() => handleOpenDialog()}
        disabled={!isValid || !dirty}
      >
        Importar Produtos
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
