import React, { createContext, useRef, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Trash } from 'assets/images/undraw_empty_cart_co35.svg';
import { Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { useGraphQL } from 'hooks/useGraphQL';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_PROVIDERS } from 'services/graphql/query/provider';

import { ImportGRVProducts } from './components/ImportGRVProducts';
import { ImportProducts } from './components/importProduct';
import { ProductTable } from './components/ProductTable';
import { useStyles } from './styles';
import { validationSchema } from './validationSchema';

export const ReceivementContext = createContext();

export function Receivement() {
  const classes = useStyles();

  const [getProviderSelect, setProviderSelect] = useState(0);
  const [getResults, setResults] = useState();
  const [importing, setImporting] = useState(false);

  const { data, loading } = useGraphQL(LIST_PROVIDERS);

  const providerOptions = (data?.listProviders || []).map((object) => ({
    value: object.id,
    label: object.description,
  }));

  const formikRef = useRef();

  const handleImportInfoOpen = () => {
    setImporting(true);
  };

  const handleReset = () => {
    setResults();
    formikRef?.current?.resetForm();
  };

  const getActionButton = (activeStep) => {
    const steps = {
      1: <ImportGRVProducts />,
      2: <ImportProducts />,
      3: <ImportProducts />,
      4: <ImportProducts />,
    };

    return steps[activeStep];
  };

  return (
    <ReceivementContext.Provider value={{ setResults, getProviderSelect }}>
      <PageTitle>Recebimento</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ provider_id: '' }}
        validationSchema={validationSchema}
        onSubmit={handleImportInfoOpen}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Field
                  variant='outlined'
                  label='Fornecedor'
                  name='provider_id'
                  size='small'
                  options={providerOptions}
                  component={Select}
                  loading={loading || importing}
                  onChange={setProviderSelect}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                {getActionButton(getProviderSelect)}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {getResults && (
        <ProductTable
          data={getResults}
          setLoading={setImporting}
          loading={importing}
          reset={handleReset}
          setResults={setResults}
        />
      )}
      {!getResults && !importing ? (
        <Grid className={classes.containerImg} container>
          <Trash className={classes.trashImage} />
        </Grid>
      ) : !getResults && importing ? (
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <></>
      )}
    </ReceivementContext.Provider>
  );
}
