import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { FormatDate, Table } from 'components';
import { LIST_B2C_ORDERS_FOR_MANAGEMENT } from 'services/graphql/query/b2c';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TablePagination } from '@mui/material';

import { OrdersManagementContext } from '..';

const columns = [
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'CÓDIGO PEDIDO', align: 'center' },
  { label: 'MARKETPLACE', align: 'center' },
  { label: 'CLIENTE', align: 'center' },
  { label: 'DATA PEDIDO', align: 'center' },
  { label: 'STATUS', align: 'center' },
  { label: 'SKU REQUISITADO', align: 'center' },
  { label: 'VOUCHER ATENDIDO', align: 'center' },
  { label: 'NOTA FISCAL', align: 'center' },
  { label: 'OBJETO', align: 'center' },
  { label: 'AÇÕES', align: 'center' },
];

export function OrdersManagementTable() {
  const { search, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useContext(OrdersManagementContext);

  const [data, setData] = useState();
  const [totalCount, setTotalCount] = useState();

  const { loading } = useQuery(LIST_B2C_ORDERS_FOR_MANAGEMENT, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      search: search,
    },
    onCompleted: (results) => {
      setData(results?.listB2COrdersForManagement?.orders);
      setTotalCount(results?.listB2COrdersForManagement?.totalCount);
    },
  });

  return (
    <>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum pedido encontrado'
        loading={loading}
        disableNumeration
      >
        {(data || []).map((item, index) => (
          <TableRow key={index}>
            <TableCell align='center'>{item?.anymarketId}</TableCell>
            <TableCell align='center'>{item?.orderCode}</TableCell>
            <TableCell align='center'>{item?.marketplace}</TableCell>
            <TableCell align='center'>{item?.client?.name}</TableCell>
            <TableCell align='center'>
              <FormatDate>{item?.orderDate}</FormatDate>
            </TableCell>
            <TableCell align='center'>{item?.businessStatus?.description}</TableCell>
            <TableCell align='center'>{item?.B2CProducts?.[0]?.sku}</TableCell>
            <TableCell align='center'>{item?.B2CProducts?.[0]?.B2CPicking?.sinister}</TableCell>
            <TableCell align='center'>{item.B2CInvoices?.number}</TableCell>
            <TableCell align='center'>{item?.B2CExpeditionItem?.mailObject}</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        ))}
      </Table>

      <TablePagination
        component='div'
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
