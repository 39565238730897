import React, { createContext, useState } from 'react';

import { Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { OrdersManagementHeader } from './components/OrdersManagementHeader';
import { OrdersManagementTable } from './components/OrdersManagementTabel';

export const OrdersManagementContext = createContext();

export function OrdersManagement() {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    setPage(0);
  };

  return (
    <>
      <PageTitle>Gerenciamento de Pedidos</PageTitle>

      <OrdersManagementContext.Provider
        value={{
          search,
          page,
          rowsPerPage,
          handleSearchChange,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
      >
        <OrdersManagementHeader />

        <Spacer size={64} />

        <OrdersManagementTable />
      </OrdersManagementContext.Provider>
    </>
  );
}
