import { gql } from '@apollo/client';

export const GENERATE_SERVICE_ORDER_TAG = gql`
  mutation GeneratePdf($sinister: String!) {
    serviceOrderTag(sinister: $sinister)
  }
`;

export const GENERATE_REPORT = gql`
  mutation GenerateReport($photoUrls: [String!]!, $sinister: String!, $description: String!) {
    generateReport(photoUrls: $photoUrls, sinister: $sinister, description: $description)
  }
`;

export const ROMANIO_TAG = gql`
  mutation RomanioTag($headerId: Int!) {
    romanioTag(headerId: $headerId)
  }
`;

export const GENERATE_INVOICE_TAG = gql`
  mutation InvoiceTag($anymarketId: String!) {
    invoiceTag(anymarketId: $anymarketId)
  }
`;

export const MINUTE_TAG = gql`
  mutation MinuteTag($data: [MinuteTagInput!]!) {
    minuteTag(data: $data)
  }
`;
