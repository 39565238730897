import React from 'react';
import { useRouteMatch } from 'react-router';

import { B2B } from 'pages/Private/Warehouse/B2B';
import { B2C } from 'pages/Private/Warehouse/B2C';
import { CosmeticScreening } from 'pages/Private/Warehouse/CosmeticScreening';
import { Dock } from 'pages/Private/Warehouse/Dock';
import { WarehouseExpedition } from 'pages/Private/Warehouse/Expedition';
import { FunctionalScreening } from 'pages/Private/Warehouse/FunctionalScreening';
import { OrdersManagement } from 'pages/Private/Warehouse/OrdersManagement';
import { Picking } from 'pages/Private/Warehouse/Picking';
import { Receivement } from 'pages/Private/Warehouse/Receivement';
import { WarehouseRegisterSigep } from 'pages/Private/Warehouse/RegisterSigep';
import { Report } from 'pages/Private/Warehouse/Report';
import { ReprintInvoice } from 'pages/Private/Warehouse/ReprintInvoice';
import { ReprintServiceTag } from 'pages/Private/Warehouse/ReprintServiceTag';
import { WarehouseRequest } from 'pages/Private/Warehouse/Request';
import { WarehouseService } from 'pages/Private/Warehouse/Service';
import { WarehouseStock } from 'pages/Private/Warehouse/Stock';
import { StockLocations } from 'pages/Private/Warehouse/StockLocations';
import { WarehouseStockResume } from 'pages/Private/Warehouse/StockResume';
import { WaitingAllocation } from 'pages/Private/Warehouse/WaitingAllocation';
import { WaitingOracle } from 'pages/Private/Warehouse/WaitingOracle';
import { Route } from 'routes/Route';

export const Warehouse = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/service`} component={WarehouseService} />
      <Route isPrivate path={`${url}/stock`} component={WarehouseStock} />
      <Route isPrivate path={`${url}/expedition`} component={WarehouseExpedition} />
      <Route isPrivate path={`${url}/request`} component={WarehouseRequest} />
      <Route isPrivate path={`${url}/sigep`} component={WarehouseRegisterSigep} />
      <Route isPrivate path={`${url}/receivement`} component={Receivement} />
      <Route isPrivate path={`${url}/functional-screening`} component={FunctionalScreening} />
      <Route isPrivate path={`${url}/cosmetic-screening`} component={CosmeticScreening} />
      <Route isPrivate path={`${url}/stock-locations`} component={StockLocations} />
      <Route isPrivate path={`${url}/waiting-allocation`} component={WaitingAllocation} />
      <Route isPrivate path={`${url}/waiting-oracle`} component={WaitingOracle} />
      <Route isPrivate path={`${url}/report`} component={Report} />
      <Route isPrivate path={`${url}/reprint-service-tags`} component={ReprintServiceTag} />
      <Route isPrivate path={`${url}/b2b`} component={B2B} />
      <Route isPrivate path={`${url}/b2c`} component={B2C} />
      <Route isPrivate path={`${url}/picking`} component={Picking} />
      <Route isPrivate path={`${url}/dock`} component={Dock} />
      <Route isPrivate path={`${url}/stock-resume`} component={WarehouseStockResume} />
      <Route isPrivate path={`${url}/reprint-invoice`} component={ReprintInvoice} />
      <Route isPrivate path={`${url}/orders-management`} component={OrdersManagement} />
    </>
  );
};
