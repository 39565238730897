import React from 'react';
import { useContext } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';

import { Text } from 'components';
import { Form, Formik } from 'formik';

import { OrdersManagementContext } from '..';

export function OrdersManagementHeader() {
  const { search, handleSearchChange } = useContext(OrdersManagementContext);
  return (
    <Formik>
      {(props) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5} lg={3} xl={3}>
              <Text
                label='Pesquisar'
                size='small'
                variant='outlined'
                endAdornment={<SearchIcon />}
                value={search}
                onChange={handleSearchChange}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
