/* eslint-disable no-restricted-imports */
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Box,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  Paper,
  Grid,
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Spacer } from 'components';
import {
  CREATE_FUNCTIONAL_SCREENING_RESPONSE,
  LIST_FUNCTIONAL_SCREENING_QUESTIONS,
  VINC_SERVICE_WITH_UNIT_ADITIONAL_DEFECT,
} from 'services/graphql/query/functional-screening';
import { GENERATE_SERVICE_ORDER_TAG } from 'services/graphql/query/pdf';

import { AditionalDefectsDialog } from './AditionalDefectsDialog';

import { FunctionalScreeningContext } from '..';

export function Questions() {
  const { sinister, handleReset, setNextStep, handleOpenNextStepDialog } = useContext(
    FunctionalScreeningContext,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isOpenAditionalDefectsDialog, setIsOpenDefectsDialog] = useState(false);
  const [defectExist, setDefectExist] = useState(false);

  const { data, loading } = useQuery(LIST_FUNCTIONAL_SCREENING_QUESTIONS, {
    skip: !sinister,
    variables: { sinister },
    fetchPolicy: 'network-only',
  });

  const handleOpenAditionalDefectDialog = () => {
    setIsOpenDefectsDialog(true);
  };

  const handleCloseAditionalDefectDialog = () => {
    setIsOpenDefectsDialog(false);
  };

  const [submitResponses] = useMutation(CREATE_FUNCTIONAL_SCREENING_RESPONSE, {
    onCompleted: (data) => {
      toast.success('Triagem funcional salva com sucesso');
      console.log('data', data);
      setNextStep(data?.submitFunctionalScreeningResponses);
      handleOpenNextStepDialog();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar a triagem funcional!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [submitBattery] = useMutation(VINC_SERVICE_WITH_UNIT_ADITIONAL_DEFECT);

  const [generatePdf] = useMutation(GENERATE_SERVICE_ORDER_TAG, {
    onCompleted: (data) => {
      const pdfBase64 = data?.serviceOrderTag || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'service_order_tag.pdf';
      link.click();
    },
    onError: (error) => {
      toast.error('Erro ao gerar o PDF!');
      console.error('Erro ao gerar o PDF:', error);
    },
  });

  if (loading || isLoading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const questions = [
    ...data.functionalScreeningQuestions,
    { id: 'battery', description: 'Saúde da bateria acima de 80%?' }, // Última pergunta sobre a saúde da bateria
  ];
  const totalSteps = questions.length;
  const currentQuestion = questions[activeStep];

  const handleAnswer = (questionId, answer) => {
    if (answer == 'Não') {
      setDefectExist(true);
    }

    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers, [questionId]: answer };

      if (activeStep < totalSteps - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        handleOpenAditionalDefectDialog();
      }

      return updatedAnswers;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async () => {
    setIsLoading(true);

    try {
      // Filtra e formata as respostas, excluindo a pergunta da bateria
      const formattedAnswers = Object.keys(answers)
        .filter((questionId) => questionId !== 'battery')
        .map((questionId) => ({
          questionId: parseInt(questionId),
          answer: answers[questionId],
        }));

      // Verifica se a pergunta da bateria foi respondida com "Não"
      console.log('answers ==>', answers);
      if (answers['battery'] === 'Não') {
        await submitBattery({
          variables: {
            data: { sinister: sinister },
          },
        });
      }

      // Submit
      await submitResponses({
        variables: {
          data: {
            responses: formattedAnswers,
            sinister: sinister,
          },
        },
      });

      // Gera o PDF
      generatePdf({ variables: { sinister } });

      handleReset();
      console.log('Respostas enviadas com sucesso:', formattedAnswers);
    } catch (error) {
      console.error('Erro ao enviar as respostas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {questions.map((question, index) => (
          <Step key={question.id}>
            <StepLabel>Pergunta {index + 1}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant='h6'>{currentQuestion.description}</Typography>
        <Spacer size={16} />
        {currentQuestion.listFuntionalScreeningQuestions?.length > 0 && (
          <>
            <Typography variant='body2'>Considere resposta negativa sempre que:</Typography>
            <Grid container spacing={4}>
              <Grid item sm={12}>
                {currentQuestion.listFuntionalScreeningQuestions.map((subQuestion) => (
                  <Typography key={subQuestion.id} variant='body2'>
                    - {subQuestion.description}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </>
        )}

        <Spacer size={54} />

        <Grid container spacing={6}>
          <Grid item sm={12} lg={6}>
            <Button
              color='primary'
              fullWidth
              onClick={() => handleAnswer(currentQuestion.id, 'Sim')}
            >
              Sim
            </Button>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Button
              color='secondary'
              fullWidth
              onClick={() => handleAnswer(currentQuestion.id, 'Não')}
            >
              Não
            </Button>
          </Grid>
        </Grid>

        <Spacer size={54} />

        <MobileStepper
          variant='dots'
          steps={totalSteps}
          position='static'
          activeStep={activeStep}
          backButton={
            <Button
              variant='outlined'
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Anterior
            </Button>
          }
          nextButton={null}
        />
      </Paper>
      <AditionalDefectsDialog
        isOpen={isOpenAditionalDefectsDialog}
        defectExist={defectExist}
        handleClose={handleCloseAditionalDefectDialog}
        sinister={sinister}
        handleFinish={handleFinish}
      />
    </Box>
  );
}
