/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@material-ui/core';

import { useMutation, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Button, Dialog } from 'components';
import {
  INATIVE_SERVICE_WITH_ADITIONAL_DEFECT,
  LIST_FUNTIONAL_DEFECTS,
  VINC_SERVICE_WITH_ADITIONAL_DEFECT,
} from 'services/graphql/query/functional-screening';

export function AditionalDefectsDialog({
  isOpen = false,
  defectExist,
  handleClose,
  sinister,
  handleFinish,
}) {
  if (!isOpen) {
    return <></>;
  }
  const [existAditionalDefect, setExistAditionalDefect] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const [options, setOptions] = useState([]);
  const { data, loading } = useQuery(LIST_FUNTIONAL_DEFECTS, { nextFetchPolicy: 'network-only' });

  const [submitResponses] = useMutation(VINC_SERVICE_WITH_ADITIONAL_DEFECT, {
    onCompleted: async () => {
      toast.success('Defeitos adicionais salvos com sucesso');
      handleClose();
      await handleFinish();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar os defeitos adicionais!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [inativeResponses] = useMutation(INATIVE_SERVICE_WITH_ADITIONAL_DEFECT, {
    onCompleted: async () => {
      handleClose();
      await handleFinish();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao inativar os defeitos adicionais!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  useEffect(() => {
    if (data && data.listFuntionalDefects) {
      setOptions(data.listFuntionalDefects);
    }
  }, [data]);

  function handleExistAditionalDefect() {
    setExistAditionalDefect(true);
  }

  async function handleConfirm() {
    console.log('Enviando os defeitos adicionaios');
    try {
      await submitResponses({
        variables: {
          data: {
            responses: selectedDefects,
            sinister: sinister,
          },
        },
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  async function handleNotConfirm() {
    try {
      await inativeResponses({
        variables: {
          data: {
            sinister: sinister,
          },
        },
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Selecione defeitos funcionais adicionais?</Dialog.Title>
      <Dialog.Content>
        <Dialog.Actions>
          {defectExist || existAditionalDefect ? (
            <Grid container spacing={2}>
              <Grid item sm={12} lg={6}>
                <Autocomplete
                  multiple
                  id='autocomplete-defects'
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  options={options}
                  loading={loading}
                  getOptionLabel={(option) => option.description}
                  onChange={(event, newValue) => setSelectedDefects(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Selecione Defeitos'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <Button fullWidth variant='contained' onClick={() => handleConfirm()}>
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={12} lg={6}>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => handleExistAditionalDefect()}
                  color='secondary'
                >
                  Adicionar defeito funcional
                </Button>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Button fullWidth variant='contained' onClick={() => handleNotConfirm()}>
                  finalizar triagem
                </Button>
              </Grid>
            </Grid>
          )}
        </Dialog.Actions>
      </Dialog.Content>
    </Dialog>
  );
}
